<template>
  <StoryblokComponent v-for="blokBody in blok.blocks" :key="blokBody._uid" v-editable="blok" :blok="blokBody" />
</template>
<script setup lang="ts">
const props = defineProps({ blok: Object })
const { formatHome } = useHrMeta()
const { stories } = await useHrStoryblok('cdn/stories', { version: 'published', content_type: 'Event' })

formatHome({ title: props.blok?.title, description: props.blok?.description, events: stories })
</script>
